<template>
    <div class="box">
        <div class="box-header with-border">
            <h3>
                <previous-route-block/>

                {{ page_title }}
            </h3>
        </div>

        <div class="box-body">

            <div class="form-group">
                <div class="row justify-content-start">
                    <div class="col-2 col-md-4">
                        <multiselect :allow-empty="true"
                                     :disabled="!employees || employees.length === 0"
                                     :options="employees"
                                     :searchable="true"
                                     @input="() => {
                                         EventBus.$emit('table-resource-pagination-changed')
                                     }"
                                     deselect-label="Убрать"
                                     label="title"
                                     placeholder="Сотрудник"
                                     required
                                     select-label="Выбрать"
                                     selected-label="Выбрано"
                                     track-by="id"
                                     v-model="employee">
                            <template slot="noResult">Не найдено</template>
                            <template slot="noOptions">Данные отсутствуют</template>
                        </multiselect>
                    </div>
                    <div class="col-4 col-md-8"></div>
                </div>
            </div>

            <div class="box-body">
                <table-items v-if="employee"
                             :columns="columns"
                             :data_search="data_search"
                             :relations="relations"
                             component="log-item"
                             :api_resource="'/employee/' + employee.id + '/logs'">
                </table-items>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Multiselect from 'vue-multiselect';
    import TableItems from './../../components/pagination/table-items';

    Vue.component('log-item', (resolve) => {
        return require(['./item'], resolve);
    });

    export default {
        name: 'logs',

        components: {
            Multiselect,
            TableItems,
        },

        props: {
            data_search: {
                type: String,
                required: false
            }
        },

        metaInfo() {
            return {
                title: this.page_title,
            }
        },

        data: () => ({
            page_title: 'Логи системы',

            employees: [],
            employee: null,

            columns: [
                {
                    title: 'ID',
                    class: null,
                    style: null,
                },
                {
                    title: 'Имя',
                    class: null,
                    style: null,
                },
                {
                    title: 'URL',
                    class: null,
                    style: null,
                },
                {
                    title: 'IP',
                    class: null,
                    style: null,
                },
                {
                    title: 'Дата',
                    class: null,
                    style: null,
                },
            ],
            relations: [
                'employee'
            ],
        }),

        async created() {
            try {
                await this.getEmployees();
            } catch (error) {
                errorHandler(error);
            }
        },

        methods: {
            async getEmployees() {
                try {
                    const employees_response = await API.apiClient.get('/employees', {
                        params: this.$apiRequestParams.filters_list,
                    });

                    this.employees = employees_response.data;
                } catch (error) {
                    errorHandler(error);
                }
            },
        },
    }
</script>

<style scoped>

</style>
